import { LoadableLazy } from '@confluence/loadable';

export const TeamCalendarsTrialPopupLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-loadable-ContentManagerChangeboardLoader" */ '../src/DatesOnAPage/TeamCalendarsTrialPopup'
			)
		).TeamCalendarsTrialPopup,
});
