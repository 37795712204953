import type { FC } from 'react';
import React, { useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import type { LinkItemProps } from '@atlaskit/menu';
import { LinkItem } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';

import { SPACE_CALENDARS } from '@confluence/named-routes';
import { LoadableAfterPaint, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { PremiumLozengeLoadable } from '@confluence/trial-components';
import { usePageSpaceKey } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';
import { SPAViewContext } from '@confluence/spa-view-context';
import { TeamCalendarsTrialPopupLoader } from '@confluence/premium-trial/entry-points/TeamCalendarsTrialPopupLoader';
import { useDatesOnAPageState } from '@confluence/premium-trial/entry-points/DatesOnAPage';

import { cssFn } from './LegacySpaceLinks';

const listStyles = xcss({
	marginTop: 'space.0',
});

const CalendarIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CalendarIcon" */ './img/CalendarIcon'))
			.CalendarIcon,
});

const i18nTooltips = defineMessages({
	calendarsTooltip: {
		id: 'side-navigation.space-navigation.calendars.tooltip',
		defaultMessage: 'View calendar',
		description: 'Tooltip message on hover of calendars space link',
	},
});

type TeamCalendarNavigationProps = Pick<LinkItemProps, 'isSelected' | 'onClick'>;

const TeamCalendarNavigationComponent: FC<TeamCalendarNavigationProps> = ({
	isSelected,
	onClick,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [spaceKey] = usePageSpaceKey();

	return (
		<Tooltip position="top" content={<FormattedMessage {...i18nTooltips.calendarsTooltip} />}>
			{(tooltipProps) => (
				<Box as="li" xcss={listStyles}>
					<LinkItem
						// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
						cssFn={cssFn}
						key="calendars"
						iconBefore={<CalendarIcon isSelected={isSelected} />}
						isSelected={isSelected}
						href={SPACE_CALENDARS.toUrl({ spaceKey })}
						{...tooltipProps}
						onClick={onClick}
						testId="space-navigation-calendars-link"
						UNSAFE_shouldDisableRouterLink
						data-vc="team-calendar-navigation"
						{...ssrPlaceholderIdProp}
					>
						<FormattedMessage
							id="side-navigation.container.calendars"
							defaultMessage="Calendars"
							description="Calendars Menu Item"
						/>
						<PremiumLozengeLoadable />
					</LinkItem>
				</Box>
			)}
		</Tooltip>
	);
};

export const TeamCalendarNavigation = (props: TeamCalendarNavigationProps) => {
	const { edition } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);

	const { showPopup, date, title, spaceName, userTimeZoneId } = useDatesOnAPageState();

	// Please also update getPremiumFeaturesWebItemKeys() in SpaceNavigation if this condition is updated
	/**
	 * showTCNav:
	 * - Premium -> true, TC is a premium feature
	 * - Standard -> true, iff user is Org/Site Admin, otherwise, false (https://hello.atlassian.net/wiki/spaces/CE2/pages/3031965072/ETC+Standard+P18N+Technical+Spec)
	 * - Free -> true, TC upsell to all Free users (https://hello.atlassian.net/wiki/spaces/CE2/pages/2906634153/ETC+P18N+Technical+Spec)
	 */
	const showTCNav = edition === ConfluenceEdition.STANDARD ? isSiteAdmin : true;

	const renderLink = () => (showTCNav ? <TeamCalendarNavigationComponent {...props} /> : null);

	return edition === ConfluenceEdition.PREMIUM && showPopup ? (
		<TeamCalendarsTrialPopupLoader
			target={renderLink()}
			title={title}
			date={date}
			calendarName={spaceName}
			userTimeZoneId={userTimeZoneId}
		/>
	) : (
		renderLink()
	);
};
