import type { Action } from 'react-sweet-state';
import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';

type DatesOnAPageStateType = {
	title: string;
	date: number;
	showPopup: boolean;
	userTimeZoneId: string;
	spaceName: string;
};

const initialState: DatesOnAPageStateType = {
	title: '',
	date: 0,
	showPopup: false,
	spaceName: '',
	userTimeZoneId: '',
};

export const actions = {
	openPopup:
		({
			title,
			date,
			showPopup,
			spaceName,
			userTimeZoneId,
		}: DatesOnAPageStateType): Action<typeof initialState> =>
		({ setState }) => {
			setState({
				title,
				date,
				showPopup,
				spaceName,
				userTimeZoneId,
			});
		},
	closePopup:
		(): Action<typeof initialState> =>
		({ setState }) => {
			setState({
				showPopup: false,
			});
		},
};

export const datesOnAPageStore = createStore<DatesOnAPageStateType, typeof actions>({
	initialState,
	actions,
	name: 'datesOnAPageStore',
});

export const useDatesOnAPageActions = createActionsHook(datesOnAPageStore);
export const useDatesOnAPageState = createStateHook(datesOnAPageStore);
